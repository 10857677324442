.testimony {
  padding: 30px;
  margin-top: 120px;
}
.testimony h1 {
  color: #5c5c5c;
  margin-top: 30px;
}
.testimony .testimony-container {
  margin-top: 50px;
  /* background: url("../../../img/mask.png"); */
  background-position: -160px;
  position: relative;
  height: 70vh;
}
.testimony .testimony-container .item {
  position: relative;
  box-shadow: 0 0 1px black;
  background-color: white;
  border-radius: 10px;
  padding: 60px 30px 30px 30px;
  color: #5c5c5c;
  margin: 0 10px;
}
.testimony .testimony-container .item .user-info {
  display: flex;
  gap: 10px;
  align-items: center;
  position: absolute;
  width: 80%;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.testimony .testimony-container .item .user-info img {
  width: 77px;
  height: 80px;
  border-radius: 10px;
}
.testimony .testimony-container .item .user-info div p {
  color: black;
  font-size: 20px;
  margin-bottom: 20px;
}

.testimony .testimony-container::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 20%;
  top: 0;
  left: 0;
  background: rgba(126, 238, 139, 0.386);
}

.react-multi-carousel-list {
  height: 100%;
  margin: 0 10px;
  z-index: 111;
}
.react-multi-carousel-item {
  margin: 0 0px;
}

.react-multiple-carousel__arrow {
  border: 3px solid var(--colorSecondary) !important;
  background-color: transparent !important;
}
.react-multiple-carousel__arrow::before {
  color: var(--colorSecondary) !important;
}

.react-multiple-carousel__arrow--left {
  left: 0 !important;
}
.react-multiple-carousel__arrow--right {
  right: 0 !important;
}

.document-container .react-multiple-carousel__arrow--left {
  background-color: #5c5c5c !important;
  border: none !important;
  top: 0;
  left: 40% !important;
}
.document-container .react-multiple-carousel__arrow--left::before {
  color: white !important;
}
.document-container .react-multiple-carousel__arrow--right {
  background: white !important;
  box-shadow: 0px 0px 1px black !important;
  border: none !important;
  top: 0;
  right: 42% !important;
}
.document-container .react-multiple-carousel__arrow--right::before {
  color: #5c5c5c !important;
}
@media screen and (max-width: 450px) {
  .testimony .testimony-container .item .user-comment {
    font-size: 14px;
  }
  .testimony .testimony-container .item .user-info img {
    width: 80px;
    height: 80px;
  }
  .testimony .testimony-container .item .user-info div p {
    color: black;
    font-size: 16px;
  }
  .testimony .testimony-container .item .user-info div p:last-child {
    font-weight: bold;
    margin-top: 10px;
  }
}
