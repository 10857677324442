* {
  box-sizing: border-box;
  outline: none;
}

html,
body {
  margin: 0;
  padding: 0;
  line-height: 150%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a,
b,
span {
  display: inline-block;
  text-decoration: none;
  color: black;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  object-fit: cover;
}

.react-multiple-carousel__arrow {
  padding: 5px 10px;
}
#popover ul {
  background-color: white;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 200;
}
#popover ul li {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}
#popover ul li:hover {
  background-color: #e7e7e7;
}

.greenButton {
  border-radius: 100px;
  border: 2px solid #0d6e29;
  color: #0d6e29;
  font-size: 14px;
  font-weight: 700;
  padding: 6px 24px;
  text-align: center;
}
.coloredButton {
  border-radius: 100px;
  font-size: 14px;
  font-weight: 700;
  padding: 6px 24px;
}
.blackButton {
  border-radius: 100px;
  border: 2px solid black;
  color: white;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 24px;
  background-color: black;
}
.bordered {
  border-radius: 30px;
}

.react-tel-input {
  font-family: Inter;
  font-size: 15px;
  position: relative;
  width: 100%;
  margin-top: 10px;
}
