.becomeAgent {
  padding: 0 30px;
  background-color: #fff;
  position: relative;
  h1 {
    line-height: 140%;
  }
  .container {
    display: flex;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
    }
    .left {
      flex: 45%;
      h1 {
        display: none;
        font-size: 25px;
      }
    }
    .right {
      flex: 55%;
      display: flex;
      flex-direction: column;
      gap: 30px;

      button {
        width: max-content;
      }
      p:nth-child(2) {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 28px */
      }
    }
  }
}
.upld-doc-lib {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #c3ddfd;
  background: #ebf5ff;
  margin: 20px 0;
  h2 {
    font-size: 16px;
  }
  p {
    font-size: 12px;
    font-weight: 200;
  }
}

/* --------------- becomeAgentForm --------------- */

.becomeAgentForm-head {
  padding: 20px 0;
  border-bottom: 1px solid rgb(205, 205, 205);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  color: #0a3764;
  margin-bottom: 40px;
  span {
    color: black;
    cursor: pointer;
    svg {
      width: 48px;
      height: 48px;
    }
  }
}
.becomeAgentForm {
  .form-action-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    button:nth-child(1) {
      background-color: transparent;
      color: #484848;
      border: 2px solid #d8e8f9;
    }
  }
  .becomeAgentForm-input-container {
    padding: 30px 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 20px;
    input {
      width: 100%;
      border: none;
    }
  }
  .becomeAgentForm-input-and-icon-container {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin: 10px 0 0 0;
    padding: 10px;
    border: 1px solid #9c9c9c;
    border-radius: 7px;
    color: var(--violet);
  }
  button {
    display: block;
    margin: auto;
    padding: 10px;
    width: 100%;
    color: white;
    border-radius: 5px;
    background-color: var(--colorPrimary);
  }
}
@media screen and (max-width: 768px) {
  .becomeAgentForm-head {
    font-size: 18px;
    span {
      color: black;
      cursor: pointer;
      svg {
        width: 48px;
        height: 48px;
      }
    }
  }
  .becomeAgent {
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 50px;
      padding: 20px 0;
      .left {
        h1 {
          display: block;
        }
      }
      .right {
        h1 {
          display: none;
        }
        p:nth-child(2) {
          font-size: 18px;
        }
      }
    }
  }
}
@media screen and (max-width: 568px) {
  .becomeAgentForm {
    .becomeAgentForm-input-container {
      grid-template-columns: 1fr;
    }
  }
}
