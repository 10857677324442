.ask-form {
  width: 90%;
  margin: auto;
  border: 1px solid rgba(216, 232, 249, 0.93);
  background: #fff;
  .ask-steppers-head {
    padding: 25px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d4d4d4;
    span {
      cursor: pointer;
      font-size: 40px;
    }
    h2 {
      color: #0a3764;
      font-size: 24px;
      font-weight: 700;
      line-height: 120%;
    }
  }
  .ask-steppers-form {
    padding: 25px 50px;
    margin-bottom: 50px;
    .ask-steppers-item-counter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80%;
      margin: 0 auto 50px auto;
      position: relative;
      overflow: hidden;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          border: 2px solid #d3dae6;
          border-radius: 50%;
          padding: 10px;
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #343741;
          // font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          z-index: 10;
          background-color: white;
        }
        .stepperCurrentStep {
          background-color: #fa0000;
          color: white;
          border: 2px solid #fa0000;
          transition: 0.3s;
        }
        .checked {
          background-color: #0e9f6e;
          color: white;
          border: 2px solid #0e9f6e;
          transition: 0.3s;
        }
        p {
          color: #111928;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }
      }
      &::after {
        content: "";
        width: 88%;
        position: absolute;
        top: 30%;
        left: 8%;
        height: 2px;
        background-color: #d3dae6;
      }
    }
    .ask-idendity-input-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0 20px;
      color: var(--violet);
      label span {
        color: var(--colorSecondary) !important;
      }
    }
    .ask-idendity-input-container-item {
      margin-top: 10px;
      display: flex;
      align-items: center;
      padding: 7px;
      gap: 10px;
      border: 1px solid var(--violet);
      border-radius: 5px;
      input {
        width: 100%;
        border: none;
        height: 100%;
      }
    }
  }
  .ask-presentation {
    table {
      border-collapse: collapse;
      margin: 10px 0;
      th,
      td {
        text-align: left;
      }
    }
    .documents {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      border-bottom: 1px solid #d3dae6;
      font-size: 14px;
      div {
        display: flex;
        align-items: center;
        gap: 10px;
        svg {
          font-size: 12px;
        }
      }
    }
  }
  .ask-finish {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    p {
      text-align: center;
      width: 60%;
      font-weight: 400;
      color: #6f6c90;
      font-size: 14px;
    }
    a {
      border-radius: 3.328px;
      background: #fa0000;
      padding: 10px 20px;
      font-size: 14px;
      color: white;
    }
  }
  .paginate-button {
    margin-top: 30px;
    display: flex;
    div {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}

@media screen and (max-width: 1268px) {
  .ask-form {
    .ask-steppers-form {
      padding: 20px;
      .ask-steppers-item-counter {
        margin-top: 30px;
        width: 100%;
        div {
          span {
            height: 30px;
            width: 30px;
          }
          p {
            font-size: 10px;
          }
        }
      }
    }
    .ask-finish {
      p {
        width: 90%;
      }
    }
  }
}
@media screen and (max-width: 968px) {
  .ask-form {
    width: 98%;
    .ask-steppers-head {
      padding: 25px 10px;
      h2 {
        color: #0a3764;
        font-size: 18px;
      }
    }

    .ask-steppers-form {
      padding: 10px;
      .ask-steppers-item-counter {
        div {
          p {
            font-size: 8px;
          }
        }
      }
    }
  }
}
.user-configuration {
  background-color: white;
  padding: 20px;
  border: 1px solid #c3ddfd;

  .user-configuration-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 20px;
  }
}

@media screen and (max-width: 660px) {
  .user-configuration {
    .user-configuration-container {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 445px) {
  .ask-form {
    .ask-steppers-form {
      .ask-idendity-input-container {
        grid-template-columns: 1fr;
      }
      .ask-steppers-item-counter {
        &::after {
          content: "";
          width: 83%;
          left: 12%;
          background-color: #d4d4d4;
        }
      }
    }
  }
}
