button {
  padding: 12px 24px;
  border: 0;
  cursor: pointer;
}
.btnLinks {
  padding: 12px;
  color: var(--background1);
}

.btnGreenLins {
  padding: 10px;
  color: white;
  background-color: #0d6e29;
  border-radius: 5px;
}
.BorderGreenLins {
  padding: 10px;
  color: #0d6e29;
  background-color: white;
  border: 1px solid #0d6e29;
  border-radius: 5px;
}
.btnNoBackground {
  background-color: transparent;
  border: solid 2px;
}
.btnNoBackground1 {
  color: var(--colorPrimary);
}
.btnNoBackground2 {
  color: var(--colorSecondary);
}

.btnBg {
  color: white;
}
.btnBg1 {
  background-color: var(--colorPrimary);
}
.btnBg2 {
  background-color: var(--colorSecondary);
}

.btnBg1 {
  background-color: var(--colorPrimary);
  border-color: var(--colorPrimary);
}
.btnBg2 {
  background-color: var(--colorSecondary);
  border-color: var(--colorSecondary);
}

/* BtnRoundedOrNot */
.btnRounded {
  border-radius: 50px;
}
.btnSimple {
  border-radius: 4px;
}
