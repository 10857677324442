.dashboardLayout {
  display: flex;
  .left {
    width: 20%;
    position: sticky;
    top: 0;
    height: 100vh;
  }
  .content {
    width: 80%;
    background: #f4f4f4;
    min-height: 100vh;
    position: relative;
    padding-bottom: 50px;
    .dashboardNav {
      padding: 20px;
      border-bottom: 1px solid #dadada;
      background: #fff;
      margin-bottom: 30px;
      position: sticky;
      top: 0;
      z-index: 100;
      .dashboardNav-hamburger {
        display: none;
        font-size: 30px;
        cursor: pointer;
      }
      .dashboardNav-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .dashboardNav-info {
          margin-left: auto;
          .dashboardNav-info-userInfo {
            display: flex;
            align-items: center;
            gap: 10px;
            span {
              color: #000;
              font-size: 14px;
              font-weight: 600;
              line-height: 140%; /* 19.6px */
            }
            p {
              color: #000;
              font-size: 12px;
              font-weight: 300;
              line-height: 140%; /* 16.8px */
            }
            .disconnect {
              cursor: pointer;
              font-size: 30px;
            }
          }
        }
      }
      .logout-popUp {
        position: absolute;
        top: 80%;
        background-color: #fff;
        padding: 10px;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
          rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        right: 30px;
        width: max-content;
        p {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 13px;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 1068px) {
  .dashboardLayout {
    display: flex;
    .left {
      width: 25%;
    }
    .content {
      width: 75%;
    }
  }
}

@media screen and (max-width: 968px) {
  .dashboardLayout {
    display: flex;
    .left {
      position: fixed;
      height: 100vh;
      top: 0;
      width: 100%;
      left: -200%;
      z-index: 200;
      transition: 0.3s;
      background-color: #0000006f;
      .dl-l-nav-container {
        background-color: white;
        width: 35%;
      }
    }
    .showNav {
      transition: 0.3s;
      left: 0;
    }
    .content {
      .dashboardNav {
        .dashboardNav-hamburger {
          display: block;
        }
      }
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .dashboardLayout {
    .left {
      .dl-l-nav-container {
        width: 85%;
      }
    }
  }
}
