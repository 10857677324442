.activity {
  margin-top: 50px;
  // padding: 0 50px;
  h3 {
    text-align: center;
  }
  .act-veille {
    margin-top: 50px;
    .item {
      display: flex;
      gap: 40px;
      padding: 100px 50px;
      .info {
        display: flex;
        gap: 30px;
        flex-direction: column;
        height: 350px;
      }
      h1 {
        font-size: 50px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      p {
        color: #797d88;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;
      }
      .img-container {
        h1 {
          font-size: 25px;
          text-align: left;
          margin-bottom: 20px;
          display: none;
        }
        div {
          height: 440px;
          display: flex;
          gap: 20px;
          img {
            border-radius: 8px;
            width: 250px;
            height: 300px;
          }
          .end {
            align-self: end;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 978px) {
  .activity {
    .act-veille {
      .item {
        h1 {
          font-size: 40px;
        }
        p {
          font-size: 18px;
        }
        .img-container {
          img {
            width: 200px;
            height: 350px;
          }
          .end {
            align-self: end;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .activity {
    .act-veille {
      .item {
        flex-direction: column;
      }
      .item:nth-child(2) {
        flex-direction: column-reverse;
      }
      .item {
        flex-wrap: wrap;
        padding: 20px;
        height: max-content;
        gap: 20px;
        .info {
          h1 {
            display: none;
          }
          height: max-content;
        }
        .img-container {
          h1 {
            display: block;
          }
          // flex-wrap: wrap;
          // height: max-content;
          div {
            padding: 20px;
            height: 400px;
            img {
              width: 50%;
              // height: 350px;
            }
            .end {
              align-self: end;
            }
          }
        }
      }
    }
  }
}
