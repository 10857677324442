.modal-box {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: -200%;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in-out;
  .modal-close {
    position: absolute;
    right: -20px;
    top: -20px;
    cursor: pointer;
    font-size: 20px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    z-index: 200;
    color: gray;
    background-color: white;
    border: solid 2px #efefef;
    border-radius: 20px;
  }
  .modal-box-content {
    background-color: white;
    padding: 20px;
    min-height: 30vh;
    max-height: 90%;
    position: relative;
    width: 50%;
    border-radius: 10px;
    .modal-container {
      width: 100%;
      max-height: 80vh;
      overflow: scroll;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: var(--colorPrimary);
    }
  }
  .modal-off-canvas {
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    max-height: none;
    border-radius: 0;
    width: 60%;
  }
}
.showIt {
  right: 0;
  transition: 0.3s ease-in-out;
}

.operationStatusModal {
  text-align: center;
}

.operationStatusModal svg {
  font-size: 80px;
}

.osm-success {
  color: var(--colorPrimary);
  border-color: var(--colorPrimary);
}

.osm-error {
  color: rgba(255, 0, 0, 0.711);
  border-color: rgba(255, 0, 0, 0.711);
}

.osm-actions {
  margin: 15px auto auto auto;
}

.osm-actions button:first-child {
  border: solid 2px;
}

.osm-actions {
  margin: 25px auto;
}

.change-modal {
  .info {
    margin: 5px;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #65676b;
    background-color: #f7f8fa;
  }
  .action {
    display: flex;
  }
  .action {
    div {
      margin-top: 10px;
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    button:first-child {
      border-radius: 3px;
      padding: 10px 15px;
      background-color: #e4e6eb;
      width: 150px;
      text-align: center;
    }
    button:last-child {
      border-radius: 3px;
      padding: 10px 15px;
      background-color: var(--colorPrimary);
      color: white;
      width: 150px;
      text-align: center;
    }
    .confirm-text {
      margin: 5px;
      border-radius: 5px;
      padding: 10px;
      font-size: 20px;
      font-weight: bold !important;
      background-color: #d6d6d6;
      text-align: center;
      margin-bottom: 30px;
    }
  }
}
@media screen and (max-width: 968px) {
  .modal-box {
    .modal-off-canvas,
    .modal-box-content {
      width: 70%;
    }
  }
}

@media screen and (max-width: 768px) {
  .modal-box {
    .modal-box-content {
      width: 90%;
    }
  }
  .modal-box {
    .modal-off-canvas {
      width: 95%;
    }
  }
}
