:root {
  --colorPrimary: #0d6e29;
  --cPDegraded1: #c1d9c8;
  --violet: #6f6c90;
  --colorSecondary: #fa0000;
  --important: #170f49;
  --dashboardBlue: #0b37d7c8;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Font/Poppins-Light.ttf");
}
* {
  font-family: "Poppins";
}
button {
  background-color: transparent;
  border: none;
}
button:disabled {
  background-color: rgb(224, 224, 224) !important;
  color: #a3a3a3 !important;
  border: none !important;
}
.btn {
  background-color: var(--colorSecondary);
  color: white;
  order: none;
  border-radius: 4px;
  padding: 16px 24px;
  cursor: pointer;
}
input[type="checkbox"] {
  cursor: pointer;
  accent-color: var(--colorPrimary);
}
.country-name {
  color: black;
}

.main-container {
  margin-top: 95px;
  min-height: 90vh;
}

/* ------------ button style ------------- */

.borderButton,
.backgroundButton {
  padding: 14px 18px;
  font-weight: bold;
  border-radius: 10px;
}

.borderButton {
  color: var(--colorSecondary);
  border: 2px solid var(--colorSecondary);
  cursor: pointer;
}

.backgroundButton {
  border: 2px solid var(--colorSecondary) !important;
  color: white;
  background-color: var(--colorSecondary);
  cursor: pointer;
}

.borderButton div,
.backgroundButton div {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* ------------ title style ------------- */
.titleRed {
  color: var(--colorSecondary);
  position: relative;
}

.titleRed::before {
  content: "";
  position: absolute;
  background: var(--colorSecondary);
  width: 30px;
  height: 2px;
  top: 110%;
}

.titleWhite {
  color: white;
  position: relative;
}

.titleWhite::before {
  content: "";
  position: absolute;
  background: white;
  width: 30px;
  height: 2px;
  top: 110%;
}

.block {
  padding-top: 50px;
}

.body-overflow-hide {
  overflow: hidden !important;
}

.input-errors {
  color: var(--colorSecondary) !important;
  padding-left: 5px;
  font-size: 14px;
}

/* ---------------------- 404 page ---------------- */

.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  height: 100vh;
}

/*  */

/* App loader */
.appLoader {
  width: 90%;
  margin: auto;
}

.fullScreenAppLoader {
  width: 100%;
  position: fixed;
  inset: 0;
  height: 100vh;
}

.appLoader img,
.fullScreenAppLoader img {
  width: 100px;
}

.fullScreenAppLoader {
  position: fixed;
  inset: 0;
  z-index: 3000000;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*  */

/* Form */

.formikFormField span {
  color: black;
}

.formikFormField svg {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;

  /* pointer-events: none; */
}

.formikFormField {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px 0;
  width: 100%;
  margin: 10px 0 0 0;
  border: 1px solid rgba(128, 128, 128, 0.342);
  overflow: hidden;
  border-radius: 8px;
}

.formikFormField input,
.formikFormField select {
  border: none;
  width: 100%;
  padding: 10px 15px;
}

.formikFormFieldRadio {
  display: flex;
  align-items: center;
  gap: 0 10px;
  cursor: pointer;
}

.formikFormFieldRadio input {
  display: none;
  position: relative;
}

.formikFormFieldRadio label {
  cursor: pointer;
}

.formikFormFieldRadio input:checked + span {
  background-color: var(--colorPrimary);
}

.formikFormFieldRadio span {
  display: inline-block;
  background-color: white;
  border-radius: 30px;
  width: 15px;
  height: 15px;
}

.formikFormFieldFile {
  position: relative;
  height: max-content;
}
.formikFormFieldFile input[type="file"] {
  position: absolute;
  left: 0;
  opacity: 0;
  padding: 0;
  height: 40px;
  cursor: pointer;
  width: 100%;
}
.formikFormFieldFile input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}
.formikFormFieldFile .text {
  padding: 10px 15px;
  z-index: -1;
  left: 0;
  margin-top: 0;
  pointer-events: none;
  border: 1px solid rgba(128, 128, 128, 0.342);
  border-radius: 8px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
}

/*  */
svg {
  pointer-events: none;
}
.ql-picker-label {
  border: 1px solid black !important;
  color: black !important;
}
.ql-picker-options span {
  color: black !important;
}

.editor-container {
  height: 70vh;
  margin: 20px 0;
}
.quill {
  height: 90%;
}
