/* ----------------- when nav change css ----------------- */

.second-nav {
  background-color: transparent !important;
}

.second-nav a {
  color: white !important;
}

.second-nav .action-pages li:first-child a {
  border: 2px solid white !important;
}

.second-nav .menu {
  color: white !important;
}

/* ----------------- desktop nav css ----------------- */
.navigation {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 12px 20px;
  width: 100%;
  z-index: 990;
  position: sticky;
  top: 0;
  background-color: white;
  border-bottom: solid 2px rgba(0, 0, 0, 0.1);
  transition: 0.4s all;
}

.navigation img {
  width: 80px;
}
.navigation .pages {
  display: flex;
  gap: 30px;
  color: black;
}

.navigation .pages a {
  padding: 10px;
  border-bottom: 3px solid transparent;
  color: black;
}

.navigation .pages a:hover {
  transition: all 0.3s ease;
  font-weight: Bold;
  border-bottom: 3px solid var(--colorSecondary);
}

.navigation .action-pages {
  display: flex;
  gap: 20px;
  align-items: center;
}

.services {
  display: flex;
  cursor: pointer;
  align-items: center;
  position: relative;
  border-bottom: 3px solid transparent;
}

.services span {
  display: flex;
  cursor: pointer;
  align-items: center;
  color: black;
}

.services:hover {
  transition: all 0.3s ease;
  font-weight: Bold;
  border-bottom: 3px solid var(--colorSecondary);
}

.services-container {
  padding: 10px;
  position: absolute;
  top: 120%;
  left: -40px;
  width: 340%;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: none;
}

.show-services-container {
  display: block !important;
}

.services-container li a {
  color: black !important;
  border: none !important;
  font-weight: lighter;
  transition: 0.5s all;
}

.services-container li a:hover {
  color: var(--colorSecondary) !important;
}

/* -------------- mobile menu -------------------- */
.mobile-services-container {
  max-height: 0px;
  opacity: 0;
  transition: 0.5s max-height;
}

.mobile-services span {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.show-mobile-services-container {
  margin-top: 10px;
  transition: 0.5s max-height !important;
  max-height: 1000px !important;
  opacity: 1 !important;
  padding-left: 10px;
}

.mobile-services-container li a {
  padding: 5px;
  display: block;
}

.mobile-services > span {
  color: black;
}

.menu {
  display: none;
  font-size: 30px;
}

.menu-dropdow {
  display: none;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgb(0 0 0 / 10%);
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 20px;
  position: absolute;
  top: 100%;
  transition: 0.9s max-height;
  width: 90vw;
  z-index: 9999;
  pointer-events: none;
}

.menu-dropdow .pages {
  display: flex !important;
  flex-direction: column;
  gap: 10px;
  pointer-events: none;
}

.menu-dropdow .action-pages {
  display: flex;
  justify-content: center;
}

.menu-dropdow .pages a {
  display: block;
  padding: 0;
  color: black !important;
}

.menu-dropdow .pages a:hover {
  border-bottom: 3px solid transparent;
}

.show-dropdown {
  opacity: 1;
  max-height: 1000px;
  transition: 0.9s all;
}

.colorBeige {
  background-color: #f1f2f4 !important;
}

/* ------------- responsive --------------- */
@media screen and (max-width: 960px) {
  .navigation .action-pages li a {
    padding: 10px 15px;
  }

  .navigation .pages {
    gap: 15px;
  }
}

@media screen and (max-width: 868px) {
  .menu {
    display: block;
    cursor: pointer;
  }

  .navigation {
    justify-content: space-between;
  }

  .navigation .pages,
  .navigation .action-pages {
    display: none;
  }

  .menu-dropdow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    pointer-events: initial;
  }

  .menu-dropdow .pages,
  .menu-dropdow .action-pages {
    display: flex;
    pointer-events: initial;
  }
}
