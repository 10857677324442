.mentions-legales {
  width: 80%;
  line-height: 1.6;
  color: #333;
  margin: 20px auto;
}

.mentions-legales h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
}

.mentions-legales h2 {
  font-size: 1.5em;
  margin-top: 1em;
}

.mentions-legales p,
.mentions-legales ul {
  margin: 0.5em 0;
}

.mentions-legales ul {
  list-style-type: none;
  padding-left: 20px;
}
.mentions-legales li {
  list-style-type: none;
  margin-bottom: 10px;
}

.mentions-legales a,
.mentions-legales span {
  color: #0066cc;
  text-decoration: none;
}

.mentions-legales a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .mentions-legales {
    width: 85%;
  }
  .mentions-legales h1 {
    font-size: 1.7em;
  }
}
