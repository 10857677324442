.termsParagraphe,
.privacy-policy {
  width: 80%;

  line-height: 1.6;
  color: #333;
  margin: 20px auto;
}

.termsParagraphe h1,
.privacy-policy h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
}

.termsParagraphe h2,
.privacy-policy h2 {
  font-size: 1.5em;
  margin-top: 1em;
}

.termsParagraphe p,
.termsParagraphe ul,
.privacy-policy p,
.privacy-policy ul {
  margin: 0.5em 0;
}

.termsParagraphe ul,
.privacy-policy ul {
  list-style-type: none;
  padding-left: 20px;
}

.termsParagraphe li,
.privacy-policy li {
  list-style-type: none;
  margin-bottom: 10px;
}

.termsParagraphe a,
.privacy-policy a {
  color: #0066cc;
  text-decoration: none;
}

.termsParagraphe a:hover,
.privacy-policy a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .termsParagraphe,
  .privacy-policy {
    width: 85%;
  }
  .termsParagraphe h1,
  .privacy-policy h1 {
    font-size: 1.7em;
  }
}
