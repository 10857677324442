/* .dashboard-element-container {
  display: flex;
}

.dashboard-element-left {
  position: sticky;
  top: 0;
  height: 100vh;
}

.dashboard-element-right {
  width: 100%;
} */
/* ------------------ request-history -------------------- */
.request-history {
  padding: 0;
  margin: auto;
  width: 95%;
}

.rh-filter {
  position: relative;
  background-color: white;
  height: max-content;
  margin-top: 20px;
}

.rh-filter li {
  cursor: pointer;
  display: inline-flex;
  text-align: center;
  padding: 5px 10px;
  margin-right: 10px;
  transition: 0.3s all;
  padding: 10px;
  border-bottom: 2px solid transparent;
}

.rh-filter li:hover {
  border-bottom: 2px solid #fa0000;
  color: #fa0000;
}

.rh-selected {
  border-bottom: 2px solid #fa0000 !important;
  color: #fa0000;
}

.rh-filter-with-input {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  background: #fff;
  padding: 10px;
  flex-wrap: wrap;
}
.rh-filter-with-input .filter-div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 2px;
  cursor: pointer;
}

.rh-filter-with-input div {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  border: 1px solid rgb(211, 211, 211);
  border-radius: 5px;
}
.rh-filter-with-input div:nth-child(1) {
  width: 45%;
}

.rh-filter-with-input div input,
.rh-filter-with-input div select {
  width: 90%;
  border: none;
}
.rh-filter-with-input div:nth-child(1) span {
  color: black;
  cursor: pointer;
  display: flex;
}
.not-filter-data {
  position: absolute;
  text-align: center;
  margin-top: 20px;
  width: 87.5%;
}

.dashboard-history-table {
  margin-top: 0px;
  overflow-x: auto;
}

table {
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
  font-size: 13px;
}

th,
td {
  text-align: center;
  padding: 10px;
}

/* th {
  background-color: #6b6f7b;
} */
td {
  /* border-bottom: 1px solid rgb(211, 211, 211); */
  background-color: white;
}
td .demandeStatut {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.notpayed {
  color: #e2aa19;
}
.not-red-payed {
  color: red;
}
.payed {
  color: #19bb46;
}

.dashboard-base-param {
  padding: 0 20px;
}
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.pagination li {
  background-color: rgb(230, 230, 230);
  padding: 0px;
}
.pagination li button {
  padding: 10px 15px;
}
.pageActive {
  background-color: #19bb46 !important;
}
.pageActive button {
  color: white !important;
}
/* -------------- user settings && reports ----------------- */
.us-title {
  width: 60%;
  margin: 20px auto;
}
.us-field,
.reports {
  width: 60%;
  margin: 50px auto;
  padding: 10px;
  border-radius: 5px;
}
.reports {
  width: 80%;
}
.us-field .info,
.reports .info {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 30px;
  justify-content: space-between;
}

.reports .info p {
  font-weight: 100;
  color: #464646d8;
  font-style: italic;
}
.us-field .info div:nth-child(1) {
  width: 40%;
}

.us-field .info:last-child,
.reports .info:last-child {
  border: none;
}

.us-field .info button,
.reports .info button {
  border-radius: 3px;
  padding: 10px 15px;
  background-color: #e4e6eb;
}
.us-field .info button:hover {
  transition: 0.3s;
  background-color: #c0c0c0;
}
.us-field .info div:first-child p:last-child {
  font-weight: bold;
  font-size: 18px;
}
.us-field .info .update,
.us-field .info .see {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

/* ------ */
.detail-field {
  margin: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
}
.detail-field th,
.detail-field td {
  text-align: left;
  padding: 10px;
}
.detail-field th {
  background-color: transparent;
}
.detail-field tr {
  border: 0;
}

.detail-field tr a {
  border-radius: 10px;
  padding: 7px 15px;
  background-color: #94a8db;
}
.detail-field tr a:hover {
  transition: 0.3s;
  background-color: #c0c0c0;
}
.update-field {
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  margin-top: 40px;
}
.update-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.update-btn a {
  width: 50%;
  text-align: center;
}
.view-detail-container {
  /* height: 85vh; */
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.clientDemandeDetail {
  background: #ffffff;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  padding: 20px;
  margin: 20px auto 0px auto;
  border: 0.832px solid rgba(216, 232, 249, 0.93);
  width: 60%;
}
.clientDemandeDetail .detailContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.clientDemandeDetail .detailContainer div h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.clientDemandeDetail .detailContainer div p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #6b6f7b;
  margin-top: 5px;
  font-weight: 300;
  border-bottom: 1px solid #d3dae6;
  padding: 10px 0;
}
.clientDemandeDetail .detailContainer div button {
  font-size: 14px;
  background-color: #000000;
  color: white;
  padding: 7px 10px;
  margin-top: 10px;
  width: 100%;
}
.rqheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paiementReclamation {
  width: 80%;
}
.paiementReclamation h5 {
  margin: 20px;
  color: #fa0000;
}
.paiementReclamation p {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.paiementReclamation p a {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: rgb(0, 153, 255);
}
/* --------------  configurated parteners ------------------------- */
.parteners-containers {
  /* background-color: #19bb46; */
  margin: 20px 0;
  padding: 20px;
  height: 70vh;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 10px;
  /* align-items: center;
  justify-content: center; */
  background-color: #ebe9e972;
}

.parteners-containers .logo-container {
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: white;
  border-radius: 100px;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
  height: max-content;
  font-size: 14px;
}
.parteners-containers .logo-container img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #6b6f7b;
}
.parteners-containers .logo-container div a {
  color: blue;
  text-decoration: underline;
  font-size: 14px;
}

.partners-container {
  background-color: white;
  padding: 10px;
}
.partners-container .pc-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dedede;
  margin-bottom: 10px;
  padding: 5px;
}
.partners-container .pc-head img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #dedede;
}
.partners-container .pc-head div {
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  .paiementReclamation {
    width: 90%;
  }
}
@media screen and (max-width: 860px) {
  .clientDemandeDetail {
    width: 100%;
  }
  .clientDemandeDetail .detailContainer {
    grid-template-columns: 1fr;
  }
  .view-detail-container {
    display: block;
  }
  .rqheader {
    display: block;
  }
  .rqheader h2 {
    margin-bottom: 20px;
  }
  .us-title,
  .us-field {
    width: 100%;
    margin: 20px auto;
  }
  .us-field .info {
    flex-wrap: wrap;
    gap: 10px;
  }
  .parteners-containers {
    grid-template-columns: 1fr;
  }
}
