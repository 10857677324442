.typeOfAsk {
  padding: 0 100px;
}

.typeOfAsk .borderButton:hover {
  border: 2px solid var(--colorSecondary) !important;
  color: white;
  background-color: var(--colorSecondary);
  cursor: pointer;
  transition: 0.5s all;
}

.typeOfAsk .back {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  font-size: 18px;
}

.typeOfAsk h2 {
  margin: 20px 0;
  font-size: 18px;
  font-weight: 400;
}
.typeOfAsk h1 {
  font-weight: 140%;
  font-size: 25px;
}
.typeOfAsk .typeOfAsk-container {
  display: flex;
  gap: 30px;
}

.typeOfAsk .typeOfAsk-container form {
  background-color: #0d6e2920;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.typeOfAsk .typeOfAsk-container form button {
  width: max-content;
  margin-left: auto;
}

.typeOfAsk .typeOfAsk-container form label {
  display: block;
  margin: 10px 0;
}

.typeOfAsk .typeOfAsk-container .typeOfAsk-requirements {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.typeOfAsk .typeOfAsk-container .typeOfAsk-requirements div {
  border-radius: 7.538px;
  border: 0.9px solid #d1d5db;
  background: #fff;
  padding: 20px;
  cursor: pointer;
}
.typeOfAsk .typeOfAsk-container .typeOfAsk-requirements div button {
  border-radius: 7.538px;
  border: 0.942px solid #00a12d;
  color: #00a12d;
  margin-top: 50px;
}

.typeOfAsk .typeOfAsk-container .typeOfAsk-requirements ul li {
  list-style: inside;
  margin: 10px 10px;
}

.typeOfAsk .typeOfAsk-container .typeOfAsk-requirements p {
  color: var(--colorSecondary);
  font-weight: bold;
}

@media screen and (max-width: 1068px) {
  .typeOfAsk .typeOfAsk-container .typeOfAsk-requirements {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 960px) {
  .typeOfAsk {
    padding: 20px 30px;
  }

  .typeOfAsk .typeOfAsk-container form {
    width: max-content;
  }

  .typeOfAsk .typeOfAsk-container {
    flex-direction: column;
    gap: 20px;
  }
}
