.footer {
  background-color: #212121;
  color: white;
  width: 100%;
}
.footer .footer-container {
  padding: 50px 50px 20px 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
}
.footer div {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
footer svg {
  font-size: 20px;
}
.footer div img {
  width: 112px;
  height: 49px;
}

.footer div p {
  width: 100%;
  font-size: 14px;
  align-items: center;
  line-height: 20px;
}
.footer a {
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
  font-size: 14px;
}

.footer .copyright {
  text-align: center;
  padding: 10px;
  background-color: #000;
}
.footer .footer-container .social {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.footer .footer-container .social div:nth-child(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}
.footer .social svg {
  font-size: 25px;
}
@media screen and (max-width: 960px) {
  .footer .footer-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 760px) {
  .footer {
    font-size: 14px;
  }
}

@media screen and (max-width: 580px) {
  .footer .footer-container {
    grid-template-columns: 1fr;
  }
}
