.information {
  margin-top: 150px;
  padding: 0 50px;
  background: #f6faf7;
  padding: 100px;
}
.information .top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.information .top h3 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%;
}
.information .article-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin-top: 50px;
}

.information .article-container > div {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  height: 452px;
  padding: 20px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 14.58%,
    rgba(56, 56, 56, 0.84) 100%
  );
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.192);
  background-blend-mode: color;
  background-position: bottom;
}
.information .article-container div div {
  margin-top: auto;
  display: flex;
  flex-direction: column;

  gap: 10px;
}
.information .article-container div div h2 {
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.2px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.information .article-container div div a {
  padding: 10px 36px;
  border-radius: 37px;
  background-color: #fa0000;
  color: white;
  font-size: 14px;
  width: max-content;
}

@media screen and (max-width: 968px) {
  .information {
    padding: 50px;
  }
  .information .article-container {
    gap: 10px;
  }
}

@media screen and (max-width: 768px) {
  .information {
    padding: 20px;
  }
  .information .article-container {
    grid-template-columns: 1fr;
  }
}
