.brief-on-us {
  padding: 30px;
  /* background: url("../../../img/Rectangle\ 8.png"); */
  margin-top: 170px;
}
.brief-on-us .container {
  display: flex;
  align-items: center;
  background-color: white;
  gap: 30px;
  border-radius: 10px;
  padding: 20px;
}
.brief-on-us .container .left {
  flex: 2.5;
}
.brief-on-us .container .left img {
  width: 100%;
  height: 100%;
}
.brief-on-us .container .right {
  flex: 2.5;
}
.brief-on-us .container .right p {
  margin: 20px 0;
  color: #5c5c5c;
}
.brief-on-us .container .right h2 {
  margin: 20px 0;
}

@media screen and (max-width: 768px) {
  .brief-on-us .container {
    display: block;
  }
}
