.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../img/loginBg.webp");
  display: flex;
  padding: 50px 70px;
  .login-container {
    width: 45%;
    margin-left: auto;
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80vh;
    position: relative;
    .back {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      position: absolute;
      top: 20px;
    }
    .top {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      img {
        width: 200px;
      }
    }
    button {
      cursor: pointer;
      display: block;
      width: 100%;
      text-align: center;
      background-color: #00a12d;
      border-radius: 6px;
      padding: 10px;
      color: white;
    }
  }
}

label span {
  color: red;
}
@media screen and (max-width: 968px) {
  .login {
    .login-container {
      width: 60%;
    }
  }
}

@media screen and (max-width: 768px) {
  .login {
    padding: 20px;
    .login-container {
      width: 100%;
    }
  }
}
