.dash-sidebar {
  border-right: 1px solid #dadada;
  height: 100vh;
  position: sticky;
  top: 0;
  .close-menu {
    position: absolute;
    padding: 20px;
    right: 0;
    cursor: pointer;
    font-size: 30px;
    display: none;
  }
  .img {
    height: 91px;
    padding: 10px 20px 20px 20px;
    img {
      width: 120px;
      padding: 20px;
    }
    border-bottom: 1px solid #dadada;
    margin-bottom: 20px;
  }
  .dash-sidebar-routes {
    padding: 20px;
    height: calc(100vh - 101px);
    overflow: auto;
    li {
      a,
      span {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 10px;
        color: #4f4f4f;
        font-weight: 600;
      }
    }
    .activeDashMenu,
    .dash-sidebar-active {
      color: #e7422c;
    }
    .chooseTypeList {
      font-size: 13px;
      padding-left: 30px;
      margin-bottom: 20px;
    }
  }
}
@media screen and (max-width: 968px) {
  .dash-sidebar {
    .close-menu {
      display: block;
    }
  }
}
