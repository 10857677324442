.simpleSteppers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  position: relative;
  // margin-bottom: 30px;
  .length,
  .currentLength,
  .currentLength2 {
    position: absolute;
    height: 2px;
    left: 0;
    top: 10px;
    width: 100%;
    z-index: 1;
    .step {
      .activeStep {
        background-color: #0a3764;
        color: white;
        border: 1px solid #0a3764;
        transition: 0.3s;
      }
      .completeStep {
        color: #0a3764;
        border: 1px solid #0a3764;
      }
    }
  }
  .length {
    background: rgba(0, 0, 0, 0.15);
  }
  .currentLength,
  .currentLength2 {
    background-color: #0a3764;
    transition: 0.3s;
  }

  .currentLength2 {
    display: none;
  }

  .last {
    div {
      p:nth-child(1) {
        width: 100% !important;
      }
    }
  }
  .step {
    .activeStep {
      background-color: #0a3764;
      color: white;
      border: 1px solid #0a3764;
      transition: 0.3s;
    }
    .completeStep {
      color: #0a3764;
      border: 1px solid #0a3764;
    }
    display: flex;
    color: rgba(0, 0, 0, 0.45);
    .activeStepInfo {
      p {
        color: black;
      }
    }
    div {
      p:nth-child(1) {
        width: max-content;
        z-index: 10;
        position: relative;
        background-color: white;
        font-weight: 500;
        font-size: 16px;
      }
      p {
        font-size: 14px;
        padding: 0px 10px;
      }
    }
    span {
      height: 30px;
      width: 30px;
      z-index: 3;
      background-color: white;
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.45);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      color: black;
    }
  }
}
.simpleSteppers {
  justify-content: space-between;
  width: 50%;
  .length,
  .currentLength {
    position: absolute;
    height: 2px;
    left: 0;
    top: 50%;
    width: 100%;
    z-index: 1;
    transform: translateY(-50%);
  }
  span {
    &::before {
      background-color: transparent !important;
    }
  }
}

.step-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin: 30px;
  button {
    padding: 12px 50px;
    font-weight: 400;
    border-radius: 4px;
  }
  button:nth-child(1) {
    color: #606060;
    background: #f3f2f8;
  }
  button:nth-child(2) {
    color: white;
    background: #001358;
  }
}
