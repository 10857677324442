.agent {
  margin-top: 170px;
  padding: 0 50px;
}
.agent .head h1 {
  color: #5c5c5c;
}
.agent .head {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.agent .agent-container {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}
.agent .agent-container .item-container div {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background-color: #dfdfdf;
  margin-bottom: 20px;
  text-align: center;
}
.agent .agent-container .item-container div .city {
  color: #5c5c5c;
}
.agent .agent-container .item-container div a {
  color: #5c5c5c;
  display: flex;
  align-items: center;
  gap: 10px;
}
.agent .agent-container .item-container {
  height: 700px;
  overflow: scroll;
}
.agent .agent-container .item-container::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 768px) {
  .agent .agent-container {
    grid-template-columns: 1fr;
  }
  .agent .head {
    display: block;
  }
  .agent .head h1 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .agent .head a {
    font-size: 12px;
  }
}
