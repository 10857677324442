.signUp {
  width: 50%;
  margin: 100px auto 0 auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
}

.signUp button {
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: center;
  background-color: #00a12d;
  border-radius: 6px;
  padding: 10px;
  color: white;
}

@media screen and (max-width: 650px) {
  .signUp {
    width: 70%;
  }
}

@media screen and (max-width: 500px) {
  .signUp {
    width: 90%;
  }
}