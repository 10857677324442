.contactHome {
  background-color: #dcdcdc;
  min-height: 80vh;
  position: relative;
  display: flex;
  justify-content: center;
}
.contactHome::after {
  width: 100%;
  height: 30%;
  background-color: white;
  content: "";
  position: absolute;
  bottom: 0;
  z-index: 2;
}
.contactHomeContent {
  margin-top: 20vh;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
  z-index: 3;
}
.contactHomeContent > h1 {
  font-weight: normal;
}
.contactHomeContent > p {
  text-align: center;
  margin-bottom: 30px;
}
.contactHomeContent_formulaire {
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
}
.contactHomeContent_formulaire > h3 {
  font-weight: normal;
  margin: 40px 0 20px 0;
  font-size: 25px;
}
.contactHomeContentInputHome {
  width: 70%;
}
.contactHomeContentInputHome_Content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}
.contactHomeContentInputHome_ContentInput {
  border: 1px solid #000;
  border-radius: 6px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40px;
}
.contactHomeContentInputHome_ContentInput > :nth-child(1) {
  font-size: 20px;
}
.contactHomeContentInputHome_ContentInput input {
  border: none;
  width: 90%;
  height: 100%;
}
.contactTextArea > :nth-child(2) {
  border-radius: 6px;
  border-style: none;
  border: 1px solid #000;
}
.contactButtonHome {
  display: flex;
  justify-content: left;
  width: 70%;
}


@media screen and (max-width: 768px) {
  .contactHomeContent {
    width: 90%;
  }
}