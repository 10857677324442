.ask-header {
  margin: 0 50px 50px 50px;
  background-color: var(--cPDegraded1);
  padding: 16px 66px;
}

.ask-header ul {
  display: flex;
  gap: 30px;
}

.ask-header ul li {
  padding: 10px;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease;
  font-weight: bold;
  cursor: pointer;
}
.ask-header ul li:hover {
  border-bottom: 2px solid var(--colorSecondary);
}
.active {
  border-bottom: 2px solid var(--colorSecondary) !important;
}

@media screen and (max-width: 768px) {
  .ask-header {
    margin: 10px !important;
    padding: 15px !important;
  }
}
