.banner {
  position: relative;
  min-height: max-content;
  background: url("../../../img/newHomeImage.webp");
  background-color: rgba(0, 0, 0, 0.365);
  background-blend-mode: darken;
  background-size: cover;
  .container {
    height: calc(100vh - 73px);
    position: relative;
    > div {
      width: 80%;
      height: 100%;
      color: white;
      line-height: 140%;
      font-size: 20px;
      font-style: normal;
      font-weight: 200;
      padding: 0 330px 0 50px;

      clip-path: circle(80% at 10% 51%);
      background: linear-gradient(#001510bd, #062605ad, #06734ce0);
      display: flex;
      flex-direction: column;
      justify-content: center;
      h1 {
        font-size: 44px;
        font-weight: 800;
        line-height: 140%;
      }
      p {
        margin: 20px 0;
        font-size: 25px;
        line-height: 140%;
      }
      div {
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
      }
    }
  }
  .band {
    padding: 50px 100px;
    background: #fafffb;
    margin-bottom: 30px;
    h3 {
      color: #000;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      text-align: center;
      line-height: 110%;
    }
    div {
      margin-top: 30px;
      display: flex;
      justify-content: space-around;
      gap: 20px;
      flex-wrap: wrap;
      img {
        width: 100px;
      }
    }
  }
}

@media screen and (max-width: 968px) {
  .banner {
    .container {
      > div {
        padding: 0 200px 0 50px;
        clip-path: circle(78% at 10% 51%);

        h1 {
          font-size: 30px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
}
@media screen and (max-width: 868px) {
  .banner {
    .container > div {
      width: 100%;
      padding: 0 50px;
      clip-path: none;

      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
      }
      div {
        margin-top: 50px;
        width: 100%;
        flex-wrap: nowrap;
        gap: 7px;
        a {
          width: 50%;
          font-size: 12px;
          padding: 5px;
          text-align: center;
        }
      }
    }
    .band {
      padding: 50px;
      h3 {
        font-size: 18px;
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .banner {
    .container > div {
      padding: 20px;
    }
    .band {
      padding: 20px;
    }
  }
}
@media screen and (max-width: 600px) {
  .banner {
    .container > div {
      h1 {
        font-size: 18px;
      }
      p {
        font-size: 14px;
      }
    }
    .band {
      padding: 20px;
    }
  }
}
