.steppers {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  margin-top: 60px;
  padding: 0 20px;
}
.steppers .brief {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 550px;
  text-align: center;
}
.steppers .brief p {
  font-size: 18px;
  color: var(--violet);
  line-height: 25px;
}
.steppers .stepper-field {
  box-shadow: 0 0 3px black;
  border-radius: 30px;
  padding: 0px 50px 50px 50px;
  width: 70%;
}
.steppers .stepper-field .step {
  padding: 40px 0;
  border-bottom: 2px solid #d9dbe9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.steppers .stepper-field .step::after {
  content: "";
  position: absolute;
  left: 0;
  height: 5px;
  width: 100%;
  background-color: #d9dbe9;
}

.steppers .stepper-field .step span {
  padding: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #d9dbe9;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  transition: 0.3s all ease;
  transition-delay: 0.5s;
  position: relative;
}
.steppers .stepper-field .step span::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  right: -43%;
  background-color: white;
  z-index: 2;
}
.steppers .stepper-field .step span::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  left: -43%;
  background-color: white;
  z-index: 2;
}
.steppers .stepper-field .step .width {
  position: absolute;
  left: 0;
  height: 5px;
  background-color: var(--colorSecondary);
  z-index: 1;
  transition: all 0.5s ease;
}
.steppers .stepper-field form {
  margin-top: 50px;
}
.steppers .paginate-button {
  display: flex;
  justify-content: space-between;
  width: 70%;
}
.steppers .paginate-button button {
  padding: 15px 40px;
  cursor: pointer;
  border: 1px solid var(--colorSecondary);
  border-radius: 5px;
  font-size: 18px;
  display: block;
}
.steppers .paginate-button button:first-child {
  color: var(--colorSecondary);
}
.steppers .paginate-button button:last-child {
  color: white;
  background-color: var(--colorSecondary);
  margin-left: auto;
}
.here-we-are {
  background-color: var(--colorSecondary) !important;
  color: white !important;
}
.all-is-done {
  background-color: var(--colorPrimary) !important;
}

@media screen and (max-width: 768px) {
  .steppers .brief {
    width: 100%;
  }
  .steppers .stepper-field {
    width: 100%;
    padding: 0 30px 30px 30px;
  }
  .steppers .paginate-button {
    width: 100%;
  }
}
