/* -----------------------Start css About Banner */
.bannerHome {
  /* background: url("../../img/bannerAbout.webp") no-repeat; */
  height: 75vh;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.4);
  background-blend-mode: color;
  position: relative;
  display: flex;
  justify-content: center;
}

.bannerHomeContent {
  position: absolute;
  top: 50%;
  display: flex;
  width: 85%;
  transform: translateY(-30%);
}

.bannerHomeContentPartGauche {
  width: 50%;
}

.bannerHomeContentPartGauche h1 {
  font-size: 48px;
}

.bannerHomeContentPartGauche span {
  font-size: 48px;
}

.bannerHomeContentPartDroite {
  width: 50%;
}

.bannerHomeContentPartDroite > p {
  color: #fff;
  margin-bottom: 20px;
}

.bannerHomeContentPartDroite_IconesHomes {
  display: flex;
  gap: 20px;
}

.bannerHomeContentPartDroite_IconesHomesContent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 5px;
  padding: 7px 20px;
}

.IconesBackground {
  background-color: var(--colorSecondary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.IconesBackground > :only-child {
  color: #fff;
  font-weight: bold;
  font-size: 25px;
}

/* -----------------------End css About Banner */

/* -----------------------Start css After About Banner */
.afterAboutBanner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.afterAboutContent {
  display: flex;
  justify-content: space-between;
  width: 85%;
}

.afterAboutContentImage1 {
  width: 40%;
}

.afterAboutContentImage1 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.afterAboutContentImage2 {
  width: 55%;
}

.afterAboutContentImage2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* -----------------------End css After About Banner */

/* -----------------------Start css After About Paragraphe */
.aboutParagraphe {
  width: 80%;
  margin: 0 auto;
  margin-top: 4rem;
}

.aboutParagrapheContent {
  margin-bottom: 27px;
}

.aboutParagrapheContent > h2 {
  margin-bottom: 10px;
  font-weight: 100;
}

.aboutParagrapheContent > p {
  font-weight: normal;
  text-align: justify;
}

/* -----------------------End css After About Paragraphe */

.react-tel-input .form-control {
  margin: 10px 0 !important;
  width: 100% !important;
  border: 1px solid #9c9c9c !important;
}
